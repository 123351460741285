.app-background-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 15em;
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
}

.form-label {
  font-weight: bold;
  color: var(--bs-gray-700);
}

.app {
  padding-bottom: 4em;
}

.footer {
  background-color: white;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
}
