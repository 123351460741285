.auth-card {
  width: 31em;
  max-width: 31em;
}

.auth-background-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.welcome-div {
  z-index: 1;
  color: white;
  max-width: 32em;
}
