.appliance-service-modal {
  position: relative;
  min-height: 40em;

  .brand-logo {
    height: 6em;
  }

  .btn {
    max-width: 20em;
  }
}
